.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.demo-logo-vertical {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, .2);
  border-radius: 6px;
}

.demo-logo {
  width: 150px;
  min-width: 120px;
  height: 32px;
  background: rgba(255, 255, 255, .2);
  border-radius: 6px;
  margin-inline-end: 24px;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: auto;
}

.dashboard-column {
  text-align: center;
}

.new-project-card {
  margin: 1rem;
  height: 20vh;
  vertical-align: middle;
}

.project-card {
  margin: 1rem;
  cursor: pointer;
}

.add-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 800px;
  background-color: lightgrey;
  padding: 2rem;
  overflow: auto;
  border-radius: 1rem;
}

.carousel-navigation {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .button-prev {
    margin: 2rem;
  }

  .button-next {
    margin: 2rem;
  }
}

.project-form {
  max-width: 600px;
  margin: auto;
  width: 600px;
}

.project-tab {
  margin: 1rem;

  .project-tab-1 {
    margin-left: 1rem;

    .project-tab-2 {
      margin-left: 1rem;
    }
  }

  .no-bold {
    font-weight: normal;
  }

  .resend-button {
    margin-bottom: 20px;
  }

  .category-select {
    width: 10rem;
  }
}

.personnel-form {
  margin: auto;
}

.user-form {
  margin: auto;
}

.director-assessment-category-list {
  margin: 2rem 0;

  .add-category-button {
    margin-top: 1rem;
  }
}

.ant-list-header {
  background-color: lightblue !important;
}

.ant-list-item {
  .selected {
    background-color: lightgrey !important;
  }
}

.add-category-form {
  margin: auto;
}

.director-assessment-responses {
  animation: fade-in 2s;

  .ant-card-bordered {
    &.selected {
      border: 2px solid !important;
    }
  }

  .ant-card-body {
    cursor: pointer;
  }

  .ant-card-head {
    text-align: center;
    background-color: lightblue;
  }

  .ant-card-body {
    text-align: center;

    h1 {
      font-size: 5rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .category-scores {
    animation: fade-in 2s;

    margin: 2rem auto;

    h2 {
      font-size: 4rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    .header {
      height: 4rem;
      white-space: break-spaces;
      margin: 1rem 0;
    }

  }

  .sub-category-scores {
    animation: fade-in 2s;

    margin: 2rem auto;

    width: 70%;

    .ant-col {
      font-size: 2rem;
    }

    .ant-collapse-expand-icon {
      height: 3rem !important;
    }

    .criteria-scores {
      animation: fade-in 2s;

      .ant-col {
        font-size: unset;
      }

    }
  }
}

.recorder-container {
  max-width: 100%;
  white-space: break-spaces;
  background-color: darkgrey;
  min-height: 600px;
  margin: auto;

  .player {
    max-width: 100%;
  }

  .hidden {
    display: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.director-performance-title {
  background-color: lightblue !important;
  border-right: 1px solid rgba(24, 33, 109, 0.88);
}

.director-performance-subtitle {
}

.category-subcategory-title {
  font-size: 1.1rem;
}

.custom-progress-bar {
  width: 95%;
  margin: 2rem auto;
}

.sv-string-viewer {
  min-width: 2.5rem;
}






